.favorites-list {
    padding: 20px;
}

.favorite-permit-li {
    display: block;
    margin: 20px;
}

.soundlyinvest-link {
    color: #18181E !important;
}

.demo.soundlyinvest {
    margin: 40px auto;
    max-width: 480px;
}