.details {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #18181E;
    color: #ffffff;
}

.details-block {
    margin: 5px 10px 10px 10px;
    padding: 5px;
    color: #18181E;
    background-color: #DEC79B;
    border-radius: 5px;
    font-size: 16px;
}

.details-address {
    margin: 5px 0 0 0;
}

.details-default {
    display: flex;
    align-content: space-between;
    padding: 5px;
}

.details-close {
    position: absolute;
    top: -10px;
    right: 10px;
    border-radius: 50px;
    padding: 4px 8px;
    font-size: 16px;
    height: 30px;
}

.details-intro {
    text-align: left;
    margin: 0 50px 0 5px;
}

@media screen and (min-width: 600px) {
    .details-default {
        padding: 15px;
    }
}

@media screen and (min-width: 1024px) {
    .details {
        width: 1024px;
    }

    .details-block {
        margin: 10px 20px 20px 20px;
        padding: 20px;
        font-size: 18px;
    }

    .details-address {
        margin: 10px 0 0 0;
    }
}