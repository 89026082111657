footer {
    color: #18181E;
    background-color: #DEC79B;
    margin: 0;
    padding: 15px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.contact {
    font-size: 24px;
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: auto;
}

.contact-icon a {
    padding: 0 10px;
    color: #18181E !important;
    font-size: 1em;
}

.contact-icon:hover {
    opacity: 0.7;
}

.copyright {
    margin-right: 5px;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 16px;
}

@media screen and (min-width: 1024px) {
    .copyright {
        margin-right: 20px;
        font-size: 18px;
    }

    .contact {
        font-size: 32px;
        margin-left: 10px;
    }
}