.search {
    width: 100%;
    background: none;
}

.search-input {
    position: fixed;
    margin-left: 10px;
    margin-right: 150px;
    margin-top: 10px;
    left: 0;
    right: 0;
    z-index: 10;
}

.search input,
.limit-input input {
    padding: 0.5rem;
    height: 50px;
    border: 2px solid #18181E;
    border-radius: 25px;
}

.search-results {
    display: block;
    list-style-type: none;
    text-align: left;
}

.limit-input {
    position: fixed;
    margin-left: auto;
    margin-right: 10px;
    width: 130px;
    margin-top: 10px;
    left: 0;
    right: 0;
    z-index: 10;
}

.to-results {
    position: fixed;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    left: 0;
    right: 0;
    z-index: 10;
}

[id^=listbox] {
    color: #18181E;
}

@media screen and (min-width: 1024px) {
    .search-input {
        margin-left: auto;
        margin-right: auto;
        width: 800px;
        right: 170px;
    }

    .limit-input {
        margin-right: auto;
        left: 840px;
    }
}