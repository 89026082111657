* {
  box-sizing: border-box;
  margin: 0;
  font-family: 'Roboto Condensed', sans-serif;
}

html {
  background-color: #f7f7f7;
  margin: 0;
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  color: #ffffff;
  background-color: #18181E;
  text-align: center;
  line-height: 1.5;
  width: 1024px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  font-size: 18px;
  padding: 15px;
  margin-top: 20px;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 20px;
  color: #DEC79B;
  background: none;
  border: 2px solid #DEC79B;
  border-radius: 5px;
}

button:hover {
  cursor: pointer;
  color: #18181E;
  background-color: #DEC79B;
}

input {
  width: 100%;
  padding: 15px;
  font-size: 18px;
  font-family: 'Roboto Condensed', sans-serif;
  border: 1px solid #ffffff;
  border-radius: 5px;
}

input[type=text] {
  color: #18181E;
}

select {
  width: 40%;
  padding: 10px;
  font-size: 18px;
}

h2 {
  color: #DEC79B;
  margin: 20px;
}

.heart-button {
  color: #18181E;
  background: none;
  border: none;
  margin: 0;
  outline: none;
  font-size: 28px;
}

.heart-button:focus {
  border: none;
}

.heart-button:disabled {
  color: #18181E;
}

.heart-button:disabled:hover {
  background: none;
  cursor: not-allowed;
}

a {
  color: #ffffff;
}

a:hover {
  text-decoration: none;
}

a:visited {
  color: #ffffff;
}

summary {
  margin-bottom: 20px;
}

.input-typ {
  margin: 0 0 20px 0;
}

.underline {
  text-decoration: underline;
}

.bold {
  font-weight: bold;
}

.error {
  background-color: #CA1551;
  color: #ffffff;
  margin: 20px 0;;
  padding: 10px;
  border-radius: 5px;
}

.success {
  background-color: #5A6351;
  color: #ffffff;
  margin: 20px 0;;
  padding: 10px;
  border-radius: 5px;
}

.white {
  color: #ffffff;
  font-family: 'Quicksand', sans-serif;
}

.demo {
  margin: 20px 0px;
  padding: 20px;
  color: #18181E;
  background: #DEC79B;
  border-radius: 5px;
}

.underline {
  text-decoration: underline;
}

.italic {
  font-style: italic;
  margin: 10px;
}