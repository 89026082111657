.title {
    font-size: 48px;
    color: #111111;
    font-family: 'Quicksand', sans-serif;
}

.title-sub {
    color: #111111;
}

.title-button {
    color: #111111;
    background: none;
    border: 2px solid #111111;
    margin: 20px 5px;
}

.title-button:hover {
    color: #ffffff;
    background: #111111;
}

.landing-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95vh;
    margin: 0;
    padding: 0;
    background: url("../../images/background.png") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-image: cover;
}

.landing-hero {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 300px;
    height: 300px;
    margin: 0 auto 250px auto;
    animation: fadeInAnimation ease 3s; 
    animation-iteration-count: 1; 
    animation-fill-mode: forwards;
}

@keyframes fadeInAnimation { 
    0% { 
        opacity: 0; 
    } 
    100% { 
        opacity: 1; 
    } 
} 

.landing-sections {
    margin: 60px 20px;
}

.landing-buttons {
    display: block;
}

.cta-button {
    margin: 20px;
}

.photo-credit {
    margin: 50px 20px;
}

@media screen and (min-width: 540px) {
    .landing-container {
        height: 100vh;
    }

    .landing-hero {
        margin: 0 auto 150px auto;
    }
}

@media screen and (min-width: 1024px) {
    .title {
        font-size: 64px;
    }

    .landing-sections {
        margin: 100px 200px;
    }

    .cta-container {
        margin-bottom: 50px;
    }

    .photo-credit {
        margin: 100px 50px;
    }
}