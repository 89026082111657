.login-form {
    margin: 10px 50px 10px 50px;
    text-align: left;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.login-container {
    margin: 30px;
}

.login-button {
    margin-bottom: 30px;
}

@media screen and (min-width: 600px) {
    .login-container {
        margin: 0 50px;
        padding: 30px;
    }
}

@media screen and (min-width: 1024px) {
    .login-container {
        margin: 0 100px;
        padding: 30px;
    }
}