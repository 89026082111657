.favorite-permit-item:hover {
    cursor: pointer;
}

.favorite-details {
    margin: 10px;
    padding: 10px;
    color: #18181E;
    background-color: #DEC79B;
    border-radius: 5px;
}