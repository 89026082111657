nav {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    color: #18181E;
    background-color: #DEC79B;
    margin: 0;
    padding: 15px;
    z-index: 20;
    display: flex;
    justify-content: space-between;
}

.nav-bar:hover {
    border-bottom: 2px solid #18181E;
}

ul {
    display: inline;
    list-style: none;
    list-style-type: none;
}

li {
    display: inline;
    padding: 5px;
}

.logo {
    text-decoration: none;
    padding: 10px;
    font-family: 'Quicksand', sans-serif;
    color: #18181E;
}

.logo:visited {
    color: #18181E;
}

.nav-bar,
.nav-bar:visited {
    color: #18181E;
    margin-left: 5px;
}

@media screen and (min-width: 1024px) {
    nav {
        padding: 20px;
    }
    
    li {
        padding: 10px;
    }
}