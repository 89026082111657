.results {
    position: relative;
    margin: 0;
    width: 100%;
    background-color: #18181E;
    color: #ffffff;
    padding: 20px;
    z-index: 16;
}

.results-item {
    margin: 15px;
}

.results-details {
    margin: 10px;
    padding: 10px;
    color: #18181E;
    background-color: #DEC79B;
    border-radius: 5px;
}

.restart-button {
    margin: 30px;
}

@media screen and (min-width: 1024px) {
    .results {
        width: 1024px;
    }

    .results-details {
        margin: 20px;
        padding: 20px;
    }
}